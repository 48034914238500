<template>
    <div>
        <div class="py-3">
            <h4>REGISTRASI PROFIL PENANGGUNG JAWAB</h4>
        </div>
        <div>
            <b-form
                @submit.prevent="editMode ? update(wakilId) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >NIK
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.nik"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nik'),
                            }"
                            placeholder="Masukkan NIK"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nik')"
                            class="form-text text-danger"
                            >{{ errors.nik[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.nama"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nama'),
                            }"
                            placeholder="Masukkan Nama"
                            style="text-transform: uppercase;"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nama')"
                            class="form-text text-danger"
                            >{{ errors.nama[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Jabatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.jabatan"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('jabatan'),
                            }"
                            placeholder="Masukkan Jabatan"
                        />
                        <em
                            v-if="errors.hasOwnProperty('jabatan')"
                            class="form-text text-danger"
                            >{{ errors.jabatan[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Surat Ketetapan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.no_sk"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('no_sk'),
                            }"
                            placeholder="Masukkan Nomor Surat Ketetapan"
                        />
                        <em
                            v-if="errors.hasOwnProperty('no_sk')"
                            class="form-text text-danger"
                            >{{ errors.no_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Tgl. Surat Ketetapan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <div class="input-group">
                            <date-picker
                                id="dateTimeInput"
                                v-model="form.tgl_sk"
                                :config="options"
                                :class="{
                                    'form-control': true,
                                    'is-invalid': errors.hasOwnProperty(
                                        'tgl_sk'
                                    ),
                                }"
                                placeholder="-Pilih Tanggal-"
                            ></date-picker>
                            <div class="input-group-append">
                                <label
                                    for="dateTimeInput"
                                    class="input-group-text"
                                >
                                    <i class="fa fa-calendar"></i>
                                </label>
                            </div>
                        </div>
                        <em
                            v-if="errors.hasOwnProperty('tgl_sk')"
                            class="form-text text-danger"
                            >{{ errors.tgl_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1" v-if="editMode">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Status Aktif
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="statusAktifOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.aktif"
                            placeholder="Pilih Status Aktif"
                        >
                        </v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Handphone
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.nomp"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('nomp'),
                            }"
                            placeholder="Masukkan Nomor Handphone"
                        />
                        <em
                            v-if="errors.hasOwnProperty('nomp')"
                            class="form-text text-danger"
                            >{{ errors.nomp[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >No. Telepon
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.notelepon"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty(
                                    'notelepon'
                                ),
                            }"
                            placeholder="Masukkan Nomor Telepon"
                        />
                        <em
                            v-if="errors.hasOwnProperty('notelepon')"
                            class="form-text text-danger"
                            >{{ errors.notelepon[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Email
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="email"
                            v-model="form.email"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('email'),
                            }"
                            placeholder="Masukkan Alamat Email"
                        />
                        <em
                            v-if="errors.hasOwnProperty('email')"
                            class="form-text text-danger"
                            >{{ errors.email[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Upload Dokumen SK
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <b-form-file
                            v-model="form.dok_sk"
                            plain
                            @change="handleFile"
                        ></b-form-file>
                        <em
                            v-if="errors.hasOwnProperty('dok_sk')"
                            class="form-text text-danger"
                            >{{ errors.dok_sk[0] }}</em
                        >
                    </div>
                </div>
                <div class="py-2">
                    <h4>ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Alamat
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            v-model="form.alamat"
                            class="form-control"
                            placeholder="Masukkan Alamat"
                        />
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Provinsi
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="provinsiOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.provinsi"
                            @input="onProvinsi(input.provinsi)"
                            placeholder="Pilih Provinsi"
                        >
                        </v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kotaOptions"
                            v-model="input.kabkota"
                            :reduce="(nama) => nama.id"
                            @input="onKabKota(input.kabkota)"
                            placeholder="Pilih Kabupaten/Kota"
                            class="bg-white"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kecamatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kecamatanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.kecamatan"
                            @input="onKecamatan(input.kecamatan)"
                            placeholder="Pilih Kecamatan"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kelurahan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kelurahanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                        ></v-select>
                        <em
                            v-if="errors.hasOwnProperty('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kodepos
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': errors.hasOwnProperty('kodepos'),
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                        />
                        <em
                            v-if="errors.hasOwnProperty('kodepos')"
                            class="form-text text-danger"
                            >{{ errors.kodepos[0] }}</em
                        >
                    </div>
                </div>
                <hr />
                <div>
                    <span>
                        <b-button variant="primary" type="submit" class="px-4">
                            <span v-if="editMode">
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i class="fa fa-edit"></i>
                                </span>
                                Edit
                            </span>
                            <span v-else>
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i class="fa fa-save"></i>
                                </span>
                                Simpan
                            </span>
                        </b-button>
                    </span>
                    <span class="float-right">
                        <b-button variant="secondary" v-show="isReset">
                            <i class="fa fa-redo-alt"></i> Reset
                        </b-button>
                    </span>
                </div>
            </b-form>
        </div>
        
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from "axios";
import { mapState } from 'vuex';
export default {
    data() {
        return {
            form: new Form({
                nik: "",
                npwpd_id: "",
                nama: "",
                nomp: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: "",
                jabatan: "",
                no_sk: "",
                tgl_sk: "",
                dok_sk: null,
                status_sk: 2,
                aktif: ''
            }),
            input: {
                provinsi: "",
                kabkota: "",
                kecamatan: "",
            },
            bentukBadanOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],
            isLoading: false,
            editMode: false,
            errors: [],
            options: {
                format: "YYYY-MM-DD",
                useCurrent: false,
            },
            editMode: false,
            isLoading: false,
            npwpdId: JSON.parse(localStorage.getItem("user")).npwpd_id,
            isReset: true,
            user: JSON.parse(localStorage.getItem("user")),
            showModalForm: false,
            wakilId: '',
            statusAktifOptions: [
                {
                    id: 0,
                    nama: "Non Aktif"
                },
                {
                    id: 1,
                    nama: "Aktif"
                }
            ]
        };
    },
    computed: {
        ...mapState([
            "dataNpwpd"
        ])
    },
    mounted() {
        this.getProvinsi();
        if (this.dataNpwpd.pimpinan) {
            this.getNpwpd(this.npwpdId);
        } else {
            this.$swal({
                icon: "error",
                title: "Data Belum Lengkap",
                text: "Silahkan lengkapi kembali data anda.",
                type: "error",
                confirmButtonClass: "btn btn-info",
                showConfirmButton: true,
                confirmButtonText: "OKE",
                showCloseButton: true,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    if (!this.user.pimpinan) {
                        this.$router.push({ name: "StepPimpinan" });
                    }
                }
            });
        }
    },
    methods: {
        handleFile(event) {
            const file = event.target.files[0];
            this.form.dok_sk = file;
        },
        // store data
        store() {
            this.isLoading = true;
            this.form.npwpd_id = this.npwpdId;
            this.form.nama = this.form.nama.toUpperCase()
            const formData = new FormData();
            formData.append("dok_sk", this.form.dok_sk);
            formData.append("nik", this.form.nik);
            formData.append("npwpd_id", this.form.npwpd_id);
            formData.append("nama", this.form.nama);
            formData.append("nomp", this.form.nomp);
            formData.append("notelepon", this.form.notelepon);
            formData.append("email", this.form.email);
            formData.append("alamat", this.form.alamat);
            formData.append(
                "wilayah_kelurahan_id",
                this.form.wilayah_kelurahan_id
            );
            formData.append("kodepos", this.form.kodepos);
            formData.append("jabatan", this.form.jabatan);
            formData.append("no_sk", this.form.no_sk);
            formData.append("tgl_sk", this.form.tgl_sk);
            formData.append("status_sk", this.form.status_sk);
            axios
                .post("/api/wajib-pajak/npwpd-wakil", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.isLoading = false;
                    this.editMode = true;
                    this.isReset = false;
                    this.errors = [];
                    this.getNpwpd(this.npwpdId);
                    this.$store.dispatch("updateNpwpd", response.data.data.id)
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil ditambah",
                        text: "Silahkan periksa kembali data anda.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000,
                    });
                    /*  this.form.reset()
                this.kodeNama= ''
                this.message = false */
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.editMode = false;
                    this.isReset = true;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                    console.log(error);
                });
        },
        // update data
        update(id) {
            this.isLoading = true;
            this.form.npwpd_id = this.npwpdId;
            this.form.nama = this.form.nama.toUpperCase()
            this.form.dok_sk = ''
            const formData = new FormData();
            formData.append("dok_sk", this.form.dok_sk);
            formData.append("nik", this.form.nik);
            formData.append("npwpd_id", this.form.npwpd_id);
            formData.append("nama", this.form.nama);
            formData.append("nomp", this.form.nomp);
            formData.append("notelepon", this.form.notelepon);
            formData.append("email", this.form.email);
            formData.append("alamat", this.form.alamat);
            formData.append("aktif", this.form.aktif);
            formData.append(
                "wilayah_kelurahan_id",
                this.form.wilayah_kelurahan_id
            );
            formData.append("kodepos", this.form.kodepos);
            formData.append("jabatan", this.form.jabatan);
            formData.append("no_sk", this.form.no_sk);
            formData.append("tgl_sk", this.form.tgl_sk);
            formData.append("status_sk", this.form.status_sk);
            formData.append("_method", "PUT");
            axios
                .post("/api/wajib-pajak/npwpd-wakil/" + id, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    this.editMode = true;
                    this.isLoading = false;
                    this.isReset = false;
                    this.errors = [];
                    this.getNpwpd(this.npwpdId);
                    this.$store.dispatch("updateNpwpd", response.data.data.id)
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil dirubah",
                        text: "Silahkan periksa kembali data anda.",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        confirmButtonText: "OKE",
                        showCloseButton: true,
                        timer: 5000,
                    });
                    /*  this.form.reset()
                this.kodeNama= ''
                this.message = false */
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                    console.log(error);
                });
        },
        //Wilayah
        // saat provinsi di input
        onProvinsi(id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = [];
                this.input.kota = null;
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKabupaten(id);
            }
        },
        // saat kab./kota di input
        onKabKota(id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKecamatan(id);
            }
        },

        // saat kab./kota di input
        onKecamatan(id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKelurahan(id);
            }
        },

        // Provinsi
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then((response) => {
                    this.provinsiOptions = response.data.data;
                    /* console.log(this.provinsiOptions) */
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kabupaten
        getKabupaten(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kotaOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kecamatan
        getKecamatan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" +
                        id
                )
                .then((response) => {
                    /*  console.log(response.data.data) */
                    this.kecamatanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // Kelurahan
        getKelurahan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kelurahanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // data detail wakil
        getWakil(id) {
            axios
                .get("/api/wajib-pajak/npwpd-wakil/" + id)
                .then((response) => {
                    const items = response.data.data
                    this.form.fill(response.data.data);
                    // console.log(this.form);
                    this.input.provinsi = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                    this.getKabupaten(this.input.provinsi)
                    this.input.kabkota = items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                    this.getKecamatan(this.input.kabkota)
                    this.input.kecamatan = items.wilayah_kelurahan.wilayah_kecamatan_id
                    this.getKelurahan(this.input.kecamatan)
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        // npwpd
        getNpwpd(id) {
            axios
                .get("/api/wajib-pajak/npwpd/" + id)
                .then((response) => {
                    const items = response.data.data;
                    // console.log(items);
                    if (items.penanggung_jawab) {
                        this.editMode = true;
                        this.isReset = false;
                        this.getWakil(items.penanggung_jawab.id);
                        this.wakilId = items.penanggung_jawab.id
                        this.$store.dispatch("isEventKirim", false);
                    } else if (!items && !items.pimpinan) {
                        this.$swal({
                            icon: "error",
                            title: "Data Belum Lengkap",
                            text: "Silahkan lengkapi kembali data anda.",
                            type: "error",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (!items.pimpinan) {
                                    this.$router.push({ name: "StepPimpinan" });
                                }
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        // reset
        reset() {
            this.form.reset();
            this.input.provinsi = null;
            this.input.kota = null;
            this.input.kecamatan = null;
            this.input.kabupaten = null;
        },
    },
};
</script>
